import "./src/styles/global.css"

// custom typefaces
import "typeface-montserrat"
import "prismjs/themes/prism.css"

import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)

export const onClientEntry = () => {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: "gtm.init_consent",
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
    personalization_storage: "denied",
    functionality_storage: "denied",
    security_storage: "denied",
  })
}
